<template>
  <v-container fluid></v-container>
</template>
<script>
import { isAuthenticated } from "@/core/services/jwt.service";
export default {
  created() {
    const _this = this;
    isAuthenticated()
      .then((param) => {
        if (param) {
          if (_this.isEngineerChannel()) {
            _this.$router.push({ name: "engineer.dashboard" });
          } else {
            _this.$router.push({ name: "admin.dashboard" });
          }
        } else {
          _this.$router.push({ name: "admin.dashboard" });
        }
      })
      .catch(() => {
        _this.$router.push({ name: "admin.login" });
      });
  },
};
</script>
